import React, { useState, useEffect } from "react";
import AppMainLayout from "../../../layouts/app-main-layout"
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import CloseIcon from '@mui/icons-material/Close';
import classNames from "classnames"
import { GET_PLAYER_ADS } from "../../../helpers/constants"
import axiosFetch from "../../../helpers/axios"
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment"
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

import "./styles.scss"

function Ads() {

    const adsMockData = {
        title: "",
        type: "",
        price: "",
        description: "",
        image: "",
        ccy: "",
        id: 0,
        playerInfo: [
            {
                name: "",
                surname: "",
                rating: "",
                phone: ""
            }
        ]
    }

    const [open, setOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false)
    const [adsInfo, setAdsInfo] = useState({ ...adsMockData })

    const [adsList, setAdsList] = useState([])
    const [photoPreview, setPhotoPreview] = useState()


    useEffect(() => {
        adsInfo.id > 0 && setPhotoPreview(adsInfo.image)
        !open && setAdsInfo({ ...adsMockData })
    }, [open])


    useEffect(() => {
       
            setIsFetching(true)
            const formData = new FormData()
            formData.append("playerId", 0);

            axiosFetch(GET_PLAYER_ADS, formData).then(res => {
                setAdsList(res)
                setIsFetching(false)
            })
      
    }, [])

    const renderAdsList = () => {
        const content = (adsList || []).filter(ad => {
            const inputDate = moment(ad.date);
            const today = moment();
            const diffDays = today.diff(inputDate, "days");
            if (diffDays < 15) {
                return ad;
            }
        })
        .map((ad, index) => {
            const { image, type, price, date, title, description, ccy, id, visible, playerInfo } = ad
           
            return (
                <div className="col-md-3" key={index}>

                    <div
                        className={classNames({
                            'home-post': true,
                            'hide-ads-container': visible == 0
                        })}
                    >
                        <div class="home-post-cover" style={{ backgroundImage: `url(${image})` }}>

                        </div>
                        <h2 class="home-post-title">
                            {title}
                        </h2>
                        <p>{description}</p>
                        <div class="home-post-details price-container">
                            <span>цена : </span>
                            <span className="product-price">{price} {ccy}</span>
                        </div>
                        <div class="home-post-details">
                            <span>Дата : </span>{moment(date).format("DD/MM/YYYY HH:mm")}  {type === "buy" ? "| Куплю" : "| Продам"}
                        </div>
                        <div class="home-post-details">
                            <span>Автор : </span>{`${playerInfo[0]?.name}`} {`${playerInfo[0]?.surname}`} {`r${playerInfo[0].rating}`}
                        </div>
                        <div class="home-post-more">
                            <a href={`tel:${playerInfo[0]?.phone.replace(/\s/g, '')}`} class="comment-more">
                                <Button
                                    variant="contained"
                                    color="success"
                                    className={classNames({
                                        'hide-show-btn': true,
                                        'hide-btn': visible == 1
                                    })}

                                >
                                    <LocalPhoneIcon /> Позвонить

                                </Button>
                            </a>
                            <span class="click-more" onClick={() => { setAdsInfo(ad); setOpen(true); }} >Детали</span>
                        </div>
                    </div>
                </div>
            )
        })

        return content
    }



    return (
        <AppMainLayout>
            <SwipeableDrawer
                anchor={"right"}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <Box
                    sx={{ width: 600 }}
                    role="presentation"
                    className="add-ads-container"
                >
                    <CloseIcon onClick={() => setOpen(false)} className="close-modal" />
                    {/* <h3>Просмотр объявления</h3> */}
                    <div className="ads-line ads-title">
                        {adsInfo.title}
                    </div>
                    <div className="ads-line">
                        Тип объявления: <b>{adsInfo.type === "buy" ? "Куплю" : "Продам"}</b>
                    </div>
                    <div className="ads-line">
                        Цена: <b>{adsInfo.price} {adsInfo.ccy}</b>
                    </div>

                    <div className="ads-line">
                        Описание: <b>{adsInfo.description}</b>
                    </div>
                    <br /><br />



                    <div
                        className={classNames({
                            // 'b-cotainer': !playerInfo.image,
                            'ads-photo': true,
                            'min-height': 600
                        })}

                        style={{ backgroundImage: `url(${photoPreview})` }}></div>
                    <a href={`tel:${adsInfo.playerInfo[0]?.phone.replace(/\s/g, '')}`} class="comment-more">
                        <Button
                            variant="contained"
                            color="success"
                            className="add-ads-btn modal"
                        >
                            {"Позвонить автору"}
                        </Button>
                    </a>
                </Box>
            </SwipeableDrawer >

            <div className="row ads-container">

                <div
                    className={classNames({
                        'col-md-12': true,
                        'no-content': isFetching
                    })}
                >
                    {isFetching ? <CircularProgress size={58} /> : adsList.length > 0 && renderAdsList()}
                </div>
            </div>
        </AppMainLayout >
    );
}


export default Ads