import React from "react";
import { NO_IMAGE_URL } from "../constants"
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames"
import { compose } from "redux";
import { amountFormatter } from "../constants/functions"
import DoneIcon from '@mui/icons-material/Done';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import "./one-player.scss"

function OnePlayer(props) {
  // console.log("props", props)
  const {
    onePlayer:
    {
      name,
      // surname,
      rating = 9999,
      id,
      image = "",
      amplua = "amplua",
      place = 888,
      wins,
      losses,
      verificated = 0,
      bronz = 0,
      gold = 0,
      silver = 0,
    },
    className,
    isCurrentUser,
    showStatisticPoints = null
  } = props



  const imgUrl = image ? image : NO_IMAGE_URL


  const renderEventDelta = (delta) => {
    return (
      <div
        className={`${Number(delta) < 0 ? "delta-minus" : "delta-plus"}`}
      >
        <span>
          {(Number(delta) > 0 || Number(delta) === 0) && "+"}{amountFormatter(delta)}
        </span>𐤃
      </div>
    )
  }

  const renderContent = () => {
    const names = name.split(" ");

    const firstName = names[0];
    const lastName = names[1];
    return (
      <Link to={`/player/${id}`} className="player-rating-container">
        <div
          className={classNames({
            'card-body': true,
            'is-current-user': isCurrentUser
          })}
        >

          <div className="d-flex flex-column align-items-center  player-date">
            <div
              className={classNames({
                'player-photo': true,
                'b-cotainer': !image
              })}

              style={{ backgroundImage: `url(${imgUrl})` }}>
              <div className="linear-border" />
              <div className="photo-gradient" />
              {verificated === 1 && <DoneIcon className="player-verificated" />}
            </div>

            <div className="bottom-player-info">
              <div className="player-name-place">
                <p className="text-muted font-size-m place">
                  <span>
                    #{place}
                  </span>
                </p>
                <p className="text-muted font-size-m player-rating">R{amountFormatter(rating)}</p>
              </div>

              <div className="player-url">
                {lastName} <br />
                {firstName}
              </div>

              <div>
                {showStatisticPoints &&
                  <>
                    <p className="text-muted font-size-m place event-statistic">

                      {renderEventDelta(showStatisticPoints)}
                      <div className="stat">
                        <b>Побед: {wins}</b>
                        <b>Поражений: {losses}</b>
                        <b>Место: #{place}</b>
                      </div>
                    </p>
                  </>
                  // :
                  // <>
                  //   <p className="text-muted font-size-m">R{amountFormatter(rating)}</p>
                  // </>
                }
              </div>
              {/* <p className="text-secondary-custom mb-1 t-center amplua">{amplua || "Нет данных1"}1</p> */}
              <div className="best-line"></div>
              <div className="place-league-container">
                {gold > 0 && <div className="place-league">
                  <EmojiEventsIcon sx={{ fill: "#FFD700" }}/>
                  <span>x{gold}</span>
                </div>}
                {silver > 0 && <div className="place-league">
                  <EmojiEventsIcon sx={{ fill: "#A9A9A9" }}/>
                  <span>x{silver}</span>
                </div>}
                {bronz > 0 && <div className="place-league">
                  <EmojiEventsIcon sx={{ fill: "#b08d57" }}/>
                  <span>x{bronz}</span>
                </div>}
              </div>
            </div>

          </div>
        </div>
      </Link>
    )
  }


  return (
    <div className={className} >
      <div className="products">
        {renderContent()}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {

}))(OnePlayer));


