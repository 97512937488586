import React, { useState, useEffect, forwardRef } from "react";
import AppMainLayout from "../../../layouts/app-main-layout"
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import TextField from '@mui/material/TextField';
import Input from "../../../helpers/input";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
    getAttachedUserId
} from "../../../selectors/mainSelector";
import classNames from "classnames"
import { GET_PLAYER_ADS, ADD_PLAYER_ADS, HIDE_ADS, DELETE_ADS, EDIT_ADS } from "../../../helpers/constants"
import CookieHelper from "../../../helpers/cookie-helper";
import axiosFetch from "../../../helpers/axios"
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { enqueueSnackbar } from "notistack";
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import "./styles.scss"


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function ProfileAds() {

    const adsMockData = {
        title: "",
        type: "",
        price: "",
        description: "",
        image: "",
        ccy: "",
        id: 0,
    }

    const [open, setOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(false)
    const [isAdding, setIsAdding] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [fetchNewAds, setFetchNewAds] = useState(false)
    const [showHidding, setShowHidding] = useState(false)
    const [deletingAds, setDeletingAds] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState({ id: null, state: false })
    const [currentHiddingAds, setCurrentHiddingAds] = useState(null)
    const [adsInfo, setAdsInfo] = useState({ ...adsMockData })

    const [adsList, setAdsList] = useState([])
    const [photoPreview, setPhotoPreview] = useState()

    const attachedUserId = useSelector(getAttachedUserId);

    useEffect(() => {
        const oAuth = CookieHelper("get", "oauth");
        !oAuth && setRedirect(true)
    }, [attachedUserId])

    useEffect(() => {
        // setOpen(false)
        // setPhotoPreview
        adsInfo.id > 0 && setPhotoPreview(adsInfo.image)
        !open && setAdsInfo({ ...adsMockData })
        // !open && setAdsInfo({ ...adsInfo, image: "" })
        setIsAdding(false)
    }, [open])

    useEffect(() => {
        if (attachedUserId.email) {
            setFetchNewAds(true)
        }
    }, [attachedUserId])

    useEffect(() => {
        if (fetchNewAds) {
            setIsFetching(true)
            const formData = new FormData()
            formData.append("playerId", attachedUserId?.playerInfo?.id);

            axiosFetch(GET_PLAYER_ADS, formData).then(res => {
                setAdsList(res)
                setIsFetching(false)
                setFetchNewAds(false)
            })
        }
    }, [fetchNewAds])

    const onChange = (state, value) => {
        const data = {
            ...adsInfo,
            [state]: value,
        };

        setAdsInfo(data);
    }

    const handleChangePhoto = (file) => {
        const data = {
            ...adsInfo,
            image: file
        }
        setAdsInfo(data)
        setPhotoPreview(URL.createObjectURL(file))
    }

    const addAds = () => {
        setIsAdding(true)
        const formData = new FormData()
        formData.append("playerId", attachedUserId?.playerInfo?.id);
        formData.append("title", adsInfo.title);
        formData.append("type", adsInfo.type);
        formData.append("price", adsInfo.price);
        formData.append("description", adsInfo.description);
        formData.append("image", adsInfo.image);
        formData.append("ccy", adsInfo.ccy);
        axiosFetch(ADD_PLAYER_ADS, formData).then(res => {
            if (res === "OK") {
                setFetchNewAds(true)
                setOpen(false)
                enqueueSnackbar(("Объявление успешно добавлено"), { variant: "success" });
            } else {
                enqueueSnackbar(("Что-то пошло не так"), { variant: "error" });
            }

        })
    }
    const editAds = () => {
        setIsAdding(true)
        const formData = new FormData()
        formData.append("playerId", attachedUserId?.playerInfo?.id);
        formData.append("title", adsInfo.title);
        formData.append("type", adsInfo.type);
        formData.append("price", adsInfo.price);
        formData.append("description", adsInfo.description);
        formData.append("image", adsInfo.image);
        formData.append("ccy", adsInfo.ccy);
        formData.append("adsId", adsInfo.id);
        axiosFetch(EDIT_ADS, formData).then(res => {
            if (res === "OK") {
                setFetchNewAds(true)
                setOpen(false)
                enqueueSnackbar(("Объявление успешно отредактировано"), { variant: "success" });
            } else {
                enqueueSnackbar(("Что-то пошло не так"), { variant: "error" });
            }
        })
    }
    console.log("adsInfo", adsInfo)
    const onShowHide = (adsId, visible) => {
        setCurrentHiddingAds(adsId)
        setShowHidding(true)

        const formData = new FormData()
        formData.append("playerId", attachedUserId?.playerInfo?.id);
        formData.append("adsId", adsId);
        formData.append("visible", visible);
        axiosFetch(HIDE_ADS, formData).then(res => {
            if (res === "OK") {
                setFetchNewAds(true)
                setShowHidding(false)
                setCurrentHiddingAds(null)
                enqueueSnackbar((Number(visible) === 1 ? "Скрыто" : "Включено"), { variant: Number(visible) === 1 ? "error" : "success" });
            } else {
                enqueueSnackbar(("Что-то пошло не так"), { variant: "error" });
            }
        })
    }

    const onDeleteAds = () => {

        setDeletingAds(true)

        const formData = new FormData()
        formData.append("playerId", attachedUserId?.playerInfo?.id);
        formData.append("adsId", showDeleteModal.id);
        axiosFetch(DELETE_ADS, formData).then(res => {
            if (res === "OK") {
                setFetchNewAds(true)
                setDeletingAds(false)
                setShowDeleteModal({ id: null, state: false })
            }
        })
    }

    const renderDeleteModal = () => {
        return (
            <Dialog
                open={showDeleteModal.state}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowDeleteModal({ id: null, state: false })}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="player-games">
                    <DialogTitle className="modal-player-name">
                        <h3>Удаляем объявление?</h3>

                    </DialogTitle>

                    <DialogActions>

                        <Button
                            onClick={() => setShowDeleteModal({ id: null, state: false })}
                            variant="contained"
                            disabled={deletingAds}
                        >
                            Отмена
                        </Button>
                        <Button
                            onClick={() => onDeleteAds()}
                            variant="contained"
                            disabled={deletingAds}>
                            Удаляем
                        </Button>
                    </DialogActions>
                </div>

            </Dialog >
        )
    }

    const renderAdsList = () => {
        const content = (adsList || []).map((ad, index) => {
            const { image, type, price, date, title, description, ccy, id, visible } = ad

            let expired = false;
            const inputDate = moment(ad.date);
            const today = moment();
            const diffDays = today.diff(inputDate, "days");
            
            if (diffDays > 15) {
                expired = true;
            }
            return (
                <div className="col-md-3" key={index}>
                    <DeleteForeverIcon className="ads-remove-icon" onClick={() => setShowDeleteModal({ id, state: true })} />
                    <div
                        className={classNames({
                            'home-post': true,
                            'hide-ads-container': Number(visible) === 0
                        })}
                    >
                        <div class="home-post-cover" style={{ backgroundImage: `url(${image})` }}>

                        </div>
                        <h2 class="home-post-title">
                            {title}
                        </h2>
                        <p>{description}</p>
                        <div class="home-post-details price-container">
                            <span>цена : </span>
                            <span className="product-price">{price} {ccy}</span>
                        </div>
                        <div class="home-post-details">
                            <span>Дата : </span>{moment(date).format("DD/MM/YYYY HH:mm")}  {type === "buy" ? "| Куплю" : "| Продам"}
                        </div>
                        {expired && <div class="ads-expired">
                            <span>Срок показа сообщения истёк. (15 дей)</span>
                        </div>}
                        <div class="home-post-more">
                            <span class="comment-more">
                                <Button
                                    onClick={() => showHidding ? {} : onShowHide(id, visible)}
                                    variant="contained"
                                    color="success"
                                    className={classNames({
                                        'hide-show-btn': true,
                                        'hide-btn': Number(visible) === 1
                                    })}
                                    disabled={currentHiddingAds === id}
                                >
                                    {Number(visible) === 1 ? "Скрыть" : "Показать"}

                                </Button>
                            </span>
                            <span class="click-more" onClick={() => { setAdsInfo(ad); setOpen(true); }} >Редактировать</span>
                        </div>
                    </div>
                </div>
            )
        })

        return content
    }

    const noAds = () => {
        return (
            <div className="no-ads">{isFetching ? <CircularProgress size={58} /> : "У вас пока нет объявлений =("} </div>
        )
    }

    const disabled = !adsInfo.description || !adsInfo.image || !adsInfo.price || !adsInfo.title || !adsInfo.type || !adsInfo.ccy || photoPreview?.length === 0
    console.log("Number(adsInfo.id) > 0 ? editAds() : addAds()", adsMockData, Number(adsInfo.id) > 0 ? 1 : 2)
    return (
        <AppMainLayout>
            {redirect && <Redirect to={`/`} />}
            {renderDeleteModal()}
            <SwipeableDrawer
                anchor={"right"}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <Box
                    sx={{ width: 600 }}
                    role="presentation"
                    className="add-ads-container"
                >
                    <CloseIcon onClick={() => setOpen(false)} className="close-modal" />
                    <h3>{Number(adsInfo.id) > 0 ? "Редактирование объявления" : "Добавление объявления"}</h3>
                    <TextField
                        id="outlined-basic-1"
                        label="Заголовок"
                        fullWidth
                        variant="outlined"
                        value={adsInfo.title}
                        onChange={(e) => onChange("title", e.target.value)}
                    />
                    <br /><br />

                    <FormControl className="ads-type-container">
                        <FormLabel id="demo-row-radio-buttons-group-label">Тип объявления</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="ads-type"
                            value={adsInfo.type}
                            onChange={(e) => onChange("type", e.target.value)}
                        >
                            <FormControlLabel value="buy" control={<Radio />} label="Куплю" />
                            <FormControlLabel value="sale" control={<Radio />} label="Продам" />
                        </RadioGroup>
                    </FormControl>

                    <TextField
                        id="outlined-basic-1"
                        label="Цена"
                        fullWidth
                        multiline
                        type="number"
                        variant="outlined"
                        // style={{ minHeight: 200 }}
                        value={adsInfo.price}
                        onChange={(e) => onChange("price", e.target.value)}
                        inputProps={{
                            type: "number",
                        }}
                    />
                    <FormControl className="ads-type-container">
                        <FormLabel id="demo-row-radio-buttons-group-label">Валюта</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            className="ads-type"
                            value={adsInfo.ccy}
                            onChange={(e) => onChange("ccy", e.target.value)}
                        >
                            <FormControlLabel value="euro" control={<Radio />} label="EURO" />
                            <FormControlLabel value="mdl" control={<Radio />} label="MDL" />
                        </RadioGroup>
                    </FormControl>
                    <br /><br />
                    <TextField
                        id="outlined-basic-1"
                        label="Описание"
                        fullWidth
                        multiline
                        variant="outlined"
                        value={adsInfo.description}
                        onChange={(e) => onChange("description", e.target.value)}
                    />
                    <br /><br />

                    <Input
                        colMd={""}
                        type={"file"}
                        placeholder={"Фото"}
                        name={"adsPhoto"}
                        onChange={(e) => handleChangePhoto(e.target.files[0])}
                    />
                    {photoPreview && <div onClick={() => setPhotoPreview("")} className="del-photo">(Удалить)</div>}
                    <div
                        className={classNames({
                            // 'b-cotainer': !playerInfo.image,
                            'ads-photo': true,
                            'min-height': photoPreview?.length > 0
                        })}

                        style={{ backgroundImage: `url(${photoPreview})` }}></div>
                    <Button
                        onClick={() => Number(adsInfo.id) > 0 ? editAds() : addAds()}
                        variant="contained"
                        color="success"
                        className="add-ads-btn modal"
                        disabled={disabled || isAdding}
                    >
                        {Number(adsInfo.id) > 0 ? "Редактировать" : "Добавить"}
                        {isAdding && <CircularProgress size={18} />}
                    </Button>
                </Box>
            </SwipeableDrawer >

            <div className="row ads-container">
                <Button
                    onClick={() => !attachedUserId?.playerInfo?.name ? enqueueSnackbar(("Неизвестные игроки не могут добавлять объявления. Привяжите анкету! "), { variant: "error" }) : setOpen(true)}
                    variant="contained"
                    color="success"
                    className="add-ads-btn"
                >
                    Добавить объявление

                </Button>
                <div
                    className={classNames({
                        'col-md-12': true,
                        // 'no-content': isFetching
                    })}
                >
                    {/* {isFetching && <CircularProgress size={58} />} */}
                    {adsList.length > 0 ? renderAdsList() : noAds()}
                </div>
            </div>
        </AppMainLayout >
    );
}


export default ProfileAds