import React, { useState, forwardRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid } from '@mui/x-data-grid';
import { PencilIcon } from "../../../../svg-icons/icon-pencil";
import { TrashIcon } from "../../../../svg-icons/icon-trash";
import {
  ADD_EVENT,
  GET_PLAYERS_LIST,
  GET_EVENTS,
  EDIT_EVENT,
  DELETE_EVENT,
  ADMIN_EMAIL,
  leagueCount,
  SAVE_PLAYER_PLACE
} from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import moment from "moment"
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { useSelector } from "react-redux";
import Box from '@mui/material/Box';
import { enqueueSnackbar } from "notistack";
import {
  getAttachedUserId
} from "../../../../selectors/mainSelector";

import "./styles.scss"


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Events(props) {

  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [fetchEvents, setFetchEvents] = useState(false)
  const [fetchDeleteEvent, setFetchDeleteEvent] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [eventName, setEventName] = useState("Воскресный турнир");
  const [eventDate, setEventDate] = useState(moment().format("DD/MM/YYYY"));
  const [playersList, setPlayersList] = useState([])
  const [playersPlaces, setPlayersPlaces] = useState({
    leaguesInfo: {
      1: { 1: { playerId: "" } },
      2: { 1: { playerId: "" } },
      3: { 1: { playerId: "" } },
      4: { 1: { playerId: "" } },
      5: { 1: { playerId: "" } },
      6: { 1: { playerId: "" } }
    }
  })
  const [editData, setEditData] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const attachedUserId = useSelector(getAttachedUserId);
  const IS_ADMIN = attachedUserId?.email === ADMIN_EMAIL;


  useEffect(() => {
    axiosFetch(GET_PLAYERS_LIST).then(res => {
      setPlayersList(res)
    })
  }, [])


  useEffect(() => {
    setIsFetching(true)
    const formData = new FormData()
    formData.append("fullInfo", 1)
    axiosFetch(GET_EVENTS, formData).then(res => {
      setEvents(res)
      setIsFetching(false)
    })

  }, [fetchEvents])

  const addEvent = () => {
    const formData = new FormData()
    formData.append("name", eventName)
    formData.append("date", eventDate)

    axiosFetch(ADD_EVENT, formData).then(res => {
      if (res === "OK") {
        setFetchEvents(!fetchEvents)
        setEventName("");
        setOpen(false);
      }
    })
  }

  const editEvent = () => {
    const formData = new FormData()
    formData.append("name", editData.name);
    formData.append("date", editData.date)
    formData.append("id", editData.id)

    axiosFetch(EDIT_EVENT, formData).then(res => {
      if (res === "OK") {
        setFetchEvents(!fetchEvents)
        setEventName("");
        setOpen(false);
      }
    })
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({})
  };

  const onCellClick = (row) => {
    setEditData(row);
    setPlayersPlaces(row.leaguesInfo)
    handleClickOpen()
  };

  const setEditEvent = (state, value) => {
    setEditData({
      ...editData,
      [state]: value
    })
  }

  const cancelEditMode = () => {
    setEditData({})
    setShowDeleteModal(false)
  }

  const onChangePlayerPlace = (playerData, place, league) => {
    const data = {
      ...playersPlaces,
      [league]: {
        ...playersPlaces[league],
        [place]: {
          ...playersPlaces[league][place],
          playerId: playerData
        }
      }
    }

    setPlayersPlaces({ ...data })
  }
  console.log("playersPlaces", playersPlaces)
  const savePlayerPlace = (place, league) => {


    const lineId = Number(playersPlaces[league][place].lineId);
    const playerId = Number(playersPlaces[league][place].playerId?.id) || "";


    console.log("place", place, league, lineId, playerId)

    const formData = new FormData()
    formData.append("place", place);
    formData.append("league", league)
    formData.append("lineId", lineId)
    formData.append("playerId", playerId)

    axiosFetch(SAVE_PLAYER_PLACE, formData).then(res => {
      if (res === "OK") {
        enqueueSnackbar(("Сохранено"), { variant: "success" });
      }
    })


  }

  const renderLeaguePlaces = (league) => {
    const places = [1, 2, 3];

    const content = places.map((place, index) => {
      return (
        <div className="place-line" key={index}>
          <div style={{ width: "60px" }}>{place} место</div>
          <div style={{ width: "230px" }}>
            <Autocomplete
              disablePortal
              id="combo-box-demo-2"
              options={playersList}
              autoHighlight
              value={playersPlaces[league][place].playerId || null}
              onChange={(event, newValue) => {
                onChangePlayerPlace(newValue, place, league);
              }}
              getOptionLabel={(option) => `${option.name} (${option.rating})`}
              renderInput={(params) => <TextField {...params} label="Выберите игрока" />}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option.name} ({option.rating})
                </Box>
              )}
            />
          </div>
          <Button
            onClick={() => savePlayerPlace(place, league)}
            variant="contained"
          >
            Сохранить
            {/* {savePlayerPlace && <CircularProgress className="circular-progress" size={18} />} */}
          </Button>
        </div>
      )
    })

    return content;
  }

  const renderLeagues = () => {
    const content = leagueCount.map((league, index) => {
      return (
        <div className="league-container" key={index}>
          <div className="league-title">{league.title}</div>
          {editData?.id && !!playersPlaces && renderLeaguePlaces(league.id)}
        </div>
      )
    })

    return content
  }

  const renderModal = () => {
    const modalValue = editData?.id ? editData.name : eventName
    const modalValueDate = editData?.id ? editData.date : eventDate
    const btnLabel = editData?.id ? "Редактировать" : "Добавить"
    const titleLabel = editData?.id ? "Редактирование события" : "Добавление события"
    console.log("playersPlaces", playersPlaces)
    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"

      >
        <div className="add-event-container" style={{ minWidth: 500 }}>
          <DialogTitle>{titleLabel}</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-basic"
              label="Наименование события"
              fullWidth
              variant="outlined"
              value={modalValue}
              onChange={(e) => !editData?.id ? setEventName(e.target.value) : setEditEvent("name", e.target.value)}
            />
            <br /><br />
            <TextField
              id="outlined-basic"
              label="Дата"
              fullWidth
              variant="outlined"
              value={modalValueDate}
              onChange={(e) => !editData?.id ? setEventDate(e.target.value) : setEditEvent("date", e.target.value)}
            />

            {editData?.id && renderLeagues()}

          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} variant="contained">Отмена</Button>
            <Button onClick={() => editData?.id ? editEvent() : addEvent()} disabled={!modalValue}>{btnLabel}</Button>
          </DialogActions>
        </div>

      </Dialog>
    )
  }

  const onDeleteResult = () => {


    setFetchDeleteEvent(true)
    const formData = new FormData()
    formData.append("id", editData.id)


    axiosFetch(DELETE_EVENT, formData).then((res) => {
      cancelEditMode()
      setFetchDeleteEvent(false)
      setFetchEvents(!fetchEvents)
    })
  }

  const renderDeleteModal = () => {

    return (
      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => cancelEditMode()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ minWidth: 500 }}>
          <DialogTitle >Удаляем событие?</DialogTitle>
          <DialogContent >

          </DialogContent>
          <DialogActions >
            <Button
              onClick={() => cancelEditMode()}
              variant="contained"
            //disabled={disabled || fetchAddGame}
            //className="add-result-btn"
            >
              Отмена
            </Button>
            <Button
              onClick={() => onDeleteResult()}
              variant="contained"
            //disabled={disabled || fetchEditGame}
            // className="add-result-btn"
            >
              Удалить
              {fetchDeleteEvent && <CircularProgress className="circular-progress" size={18} />}
            </Button>


          </DialogActions>
        </div>

      </Dialog>
    )
  }

  const onShowDeleteData = (row) => {
    setEditData(row)
    setShowDeleteModal(true)
  }

  const renderActivity = ({ row }) => {
    return (
      <div className="action-icons">
        <PencilIcon
          onClick={() => onCellClick(row)}
          className="pencil-icon"
        />
        <TrashIcon
          onClick={() => onShowDeleteData(row)}
          className="trash-icon"
        />
      </div>
    );
  };

  const renderEventName = ({ row }) => {
    console.log("{row.name}", row.leaguesInfo)
    const isPlayers = row.leaguesInfo[1][1]?.playerId || row.leaguesInfo[2][1]?.playerId || row.leaguesInfo[3][1]?.playerId || row.leaguesInfo[4][1]?.playerId
    return (
      <div className={`${isPlayers && "filled-event"}`}>
        {row.name}
      </div>
    )
  }

  const columnsAdmin = [
    // {
    //   field: 'id',
    //   headerName: '#',
    //   renderCell: (row) => {
    //     return renderActivity(row);
    //   },
    // },
    {
      field: 'name', headerName: 'Наименование события', flex: 1,
      renderCell: (row) => {
        return renderEventName(row);
      },

    },
    { field: 'date', headerName: 'Дата', flex: 1 },
    {
      field: "activity",
      headerName: IS_ADMIN ? "Действия" : "Просмотр",
      cellClassName: "action-buttons",
      flex: 1,
      renderCell: (row) => {
        return renderActivity(row);
      },
    },
  ];
  const columns = [
    { field: 'name', headerName: 'Наименование события', flex: 1 },
    { field: 'date', headerName: 'Дата', flex: 1 },
  ];

  return (
    <>
      {renderModal()}
      {renderDeleteModal()}
      {IS_ADMIN && <Button variant="contained" style={{ margin: "0 auto 20px", display: "block" }} onClick={handleClickOpen}>
        Добавить событие
      </Button>}
      <Grid container spacing={1} className="events-container">
        <Grid item xs={12}>
          {isFetching ? <CircularProgress className="circular-progress" size={18} /> :
            <DataGrid
              rows={events}
              columns={IS_ADMIN ? columnsAdmin : columns}
              className="bg-white events"
              style={{ maxWidth: IS_ADMIN ? 800 : 500, margin: "0 auto" }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection={false}
            />}
        </Grid>
      </Grid>

    </>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(Events)
);