import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import HomePage from '../pages/homepage';
import HomePageNew from '../pages/homepage-new'
import PlayerDetails from '../pages/player-details';

import CheckDeltaPage from '../pages/check-delta';
import EventsPage from '../pages/events';
// import EventDetailsPage from '../pages/events-details';
import PlayersPage from '../pages/players';
import PrivacyPolicy from '../pages/privacy-policy';
import AddResultsPage from '../pages/add-results';
import Ads from '../pages/ads';
import ProfileAds from '../pages/profile-ads';
import AfishaPage from '../pages/afisha';
import Profile from '../pages/profile';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import "./styles.scss"

class App extends Component {

    render() {
        return (
            <Router>
                
                    <Switch>
                        <Route path="/" exact component={HomePage} />
                        <Route path="/new-page" exact component={HomePageNew} />
                        <Route path="/player/:playerId" exact component={PlayerDetails} />

                        <Route path="/check-delta" exact component={CheckDeltaPage} />
                        <Route path="/events" exact component={EventsPage} />
                        {/* <Route path="/events/:eventId" exact component={EventDetailsPage} /> */}
                        <Route path="/afisha" exact component={AfishaPage} />
                        <Route path="/players" exact component={PlayersPage} />
                        <Route path="/add-result" exact component={AddResultsPage} />
                        <Route path="/profile-ads" exact component={ProfileAds} />
                        <Route path="/ads" exact component={Ads} />
                        <Route path="/profile" exact component={Profile} />
                        <Route path="/privacy-policy" exact component={PrivacyPolicy} />.
                        <Redirect to="/" />
                    </Switch>
            </Router>
        )
    };
}

const mapStateToProps = (state) => ({
   
});

export default withRouter(compose(connect(mapStateToProps, {
   
}))(App));

