import React, { useState, useEffect, forwardRef } from "react";
import { withRouter, useParams, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Spinner from "../../../helpers/spinner"
import {
  GET_PLAYER_INFO,
  GET_PLAYERS_LIST,
  GET_OPPONENT_GAMES,
  NO_IMAGE_URL,
  leagueCount
} from "../../../helpers/constants"
import { EyeIcon } from "../../../helpers/icons/icon-eye"
import classNames from "classnames"
import { amountFormatter } from "../../../helpers/constants/functions"
import axiosFetch from "../../../helpers/axios"
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import AppMainLayout from "../../../layouts/app-main-layout"
import BeenhereIcon from '@mui/icons-material/Beenhere';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useSelector } from "react-redux";
import {
  getAttachedUserId
} from "../../../selectors/mainSelector";
import "./styles.scss"

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function CustomTabPanel(props) {
  const { children, selectedTab, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {selectedTab === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}


function PlayerDetails() {
  const { playerId } = useParams();
  const [playerInfo, setPlayerInfo] = useState({})
  const [fetchPlayerInfo, setFetchPlayerInfo] = useState(false)
  const [showGamesList, setShowGamesList] = useState(false)
  const [fetchingOpponentGames, setFetchingOpponentGames] = useState(false)
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [playersList, setPlayersList] = useState([])
  const [opponentGames, setOpponentGames] = useState([])
  const [oponent, setOponent] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0);
  const [playerGamesData, setPlayerGamesData] = useState(null)

  const attachedUserId = useSelector(getAttachedUserId);

  useEffect(() => {
    if (!!oponent?.id) {
      setFetchingOpponentGames(true)
      const formData = new FormData()
      formData.append("playerId", playerId)
      formData.append("opponentId", oponent?.id)
      axiosFetch(GET_OPPONENT_GAMES, formData).then(res => {

        setShowGamesList(true)
        setOpponentGames(res)

        setTimeout(() => {
          setFetchingOpponentGames(false)
        }, 1000);
      })
    } else {
      setShowGamesList(false)
    }

  }, [oponent, playerId])

  useEffect(() => {
    setOpponentGames([])
    setOponent(null)
  }, [playerId])

  useEffect(() => {

    setFetchPlayerInfo(true)
    const formData = new FormData()
    formData.append("playerId", playerId)

    axiosFetch(GET_PLAYER_INFO, formData).then(res => {
      //setPlayerName(res[0]?.playerOne || "")
      setPlayerInfo(res[0])
      document.title = `${res[0].surname} ${res[0].name} | TCA | Rating`
      setTimeout(() => {
        setFetchPlayerInfo(false)
        setTimeout(() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }, 500);
      }, 1000);
    })

    // axiosFetch(GET_PLAYER_CHART_STATISTIC, formData).then(res => {
    //   setPlayerChartStatistic(res)
    //   setTimeout(() => {
    //     setFetchPlayerInfo(false)
    //   }, 1000);
    // })

  }, [playerId])

  useEffect(() => {
    axiosFetch(GET_PLAYERS_LIST).then(res => {
      setPlayersList(res)
    })
  }, [])

  // const statisticChart = () => {
  //   return (
  //     <LineChart
  //       width={700}
  //       height={300}
  //       data={playerChartStatistic}
  //       margin={{
  //         top: 5,
  //         right: 30,
  //         left: 20,
  //         bottom: 5
  //       }}
  //     >
  //       <CartesianGrid strokeDasharray="3 3" />
  //       <XAxis dataKey="name" />
  //       <YAxis yAxisId="left" />
  //       <YAxis yAxisId="right" orientation="right" />
  //       <Tooltip />
  //       <Legend />
  //       <Line
  //         yAxisId="left"
  //         type="monotone"
  //         dataKey="place"
  //         stroke="#15cdbc"
  //         name={"Место"}
  //         activeDot={{ r: 8 }}
  //       />

  //       <Line
  //         yAxisId="left"
  //         type="monotone"
  //         dataKey="wins"
  //         name={"Победы"}
  //         stroke=" #066e29"
  //         activeDot={{ r: 8 }}
  //       />
  //       <Line
  //         yAxisId="left"
  //         type="monotone"
  //         dataKey="losses"
  //         name={"Поражения"}
  //         stroke="#ff0000"
  //         activeDot={{ r: 8 }}
  //       />
  //       <Line
  //         yAxisId="left"
  //         type="monotone"
  //         dataKey="delta"
  //         stroke="#8884d8"
  //         name={"Дельта"}
  //         activeDot={{ r: 8 }}
  //       />

  //     </LineChart>
  //   )
  // }

  const renderLast5MatchesWithOpponent = (matches) => {
    const content = matches.map((match, index) => {
      const { scoreOne, scoreTwo, delta, event } = match
      const color = Number(scoreOne) > Number(scoreTwo) ? "green" : "red"
      return (
        <div className={`one-match ${color}`}
          title={`Дельта: ${delta} / ${event}`} key={index}>
          {/* <div /> */}
          <span style={{ color }}>{scoreOne}:{scoreTwo}</span>
        </div>
      )
    })
    return content
  }

  const renderLast5Games = () => {
    const content = playerInfo.last5Games.map((game, index) => {
      const { opponentName, opponentGames, opponentId } = game
      return (
        <li key={index}>
          <span className="text-secondary-custom last-match-result">

            <div className="p2p-last-5-matches">
              <div className="p2p-score">
                {opponentGames[0].scoreOne}:{opponentGames[0].scoreTwo}
              </div>
              <div className="last-matches-container">
                {renderLast5MatchesWithOpponent(opponentGames)}
              </div>
            </div>
          </span>
          <h6 className="mb-0 last-matches-player-name">
            <Link to={`/player/${opponentId}`} >{opponentName}</Link>
          </h6>
        </li>
      )
    })

    return content
  }


  const percent = playerInfo.setWin * 100 / (playerInfo.setLose + playerInfo.setWin);
  let percentLast3 = 0
  let percentLast3Games = 0

  if (playerInfo?.last3Events) {
    percentLast3 = playerInfo?.last3Events[0].wins * 100 / (playerInfo.last3Events[0].losses + playerInfo.last3Events[0].wins);
    percentLast3Games = playerInfo?.last3Events[0].winsGames * 100 / (playerInfo.last3Events[0].lossesGames + playerInfo.last3Events[0].winsGames);
  }
  const image = playerInfo.image && playerInfo.image.length > 0 ? playerInfo.image : NO_IMAGE_URL;

  const renderAllStatistic = () => {
    return (
      <div className="card mt-3 container-romb">
        <ul className="list-group list-group-flush">
          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="mb-0">
              Сыграно турниров:
            </h6>
            <span className="text-secondary-custom">
              {playerInfo.totalEvents}
            </span>
          </li>

          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="mb-0">
              Выиграно сетов:
            </h6>
            <span className="text-secondary-custom">
              {playerInfo.setWin}
            </span>
          </li>

          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="mb-0">
              Проиграно сетов:
            </h6>
            <span className="text-secondary-custom">
              {playerInfo.setLose}
            </span>
          </li>

          <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
            <h6 className="mb-0">
              Процент успеха:
            </h6>
            <span className="text-secondary-custom ">
              {percent ? amountFormatter(percent) : 0}%
            </span>
          </li>

        </ul>
      </div>
    )
  }

  const renderStatisticFor3events = () => {
    return (
      <div className="col-sm-12 mb-3">
        <h6 className="">
          Статистика последних 4-х активных событий
        </h6>
        <div className="card h-100">
          <div className="card-body-profile">

            {playerInfo.last3Events && <ul className="list-widget last-3-events">
              <li >
                <h6 className="mb-0">
                  Дельта
                </h6>
                <span
                  className={`text-secondary-custom last-match-result ${Number(playerInfo.last3Events[0].delta) < 0 ? "delta-minus" : "delta-plus"}`}
                >
                  {Number(playerInfo.last3Events[0].delta) >= 0 && "+"}{playerInfo.last3Events && amountFormatter(playerInfo.last3Events[0].delta)}
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  Выиграные сеты
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.last3Events && playerInfo.last3Events[0].wins}
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  Проигранные сеты
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.last3Events && playerInfo.last3Events[0].losses}
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  Процент успеха по сетам:
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {percentLast3 ? amountFormatter(percentLast3) : 0}%
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  Всего побед
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.last3Events && playerInfo.last3Events[0].winsGames}
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  Всего поражений
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.last3Events && playerInfo.last3Events[0].lossesGames}
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  Процент успеха по играм:
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {percentLast3Games ? amountFormatter(percentLast3Games) : 0}%
                </span>
              </li>


            </ul>}
          </div>
        </div>
      </div>
    )
  }

  const renderPlayerLast5Games = () => {
    return (
      <div className="col-sm-12 mb-3">
        <h6 className="">
          Последние 7 игр
        </h6>
        <div className="card h-100">
          <div className="card-body-profile">

            <ul className="list-widget last-7-games">
              {playerInfo.last5Games && renderLast5Games()}
            </ul>
          </div>
        </div>
      </div>
    )
  }


  const renderPlayerCard = () => {
    return (
      <div className="card player-card">
        {/* <div className="card-bg" /> */}
        <div className="card-body">
          <div className="d-flex flex-column align-items-center text-center player-data">
            {/* <img src={test} alt="Admin" className="rounded-circle w-100" width="150" /> */}
            <div
              className={classNames({
                'player-photo': true,
                'b-cotainer': !playerInfo.image
                //`${cName}`: true
              })}

              style={{ backgroundImage: `url(${image})` }}></div>
            {playerInfo.verificated === 1 && <BeenhereIcon className="player-verificated profile-page-icon" />}
            <div className="mt-3">
              <div className="place-rating details">
                {Number(playerInfo.visibleInRating) > 0 ?
                  <p className="text-muted font-size-m player-place-custom">#{playerInfo.place} </p> :
                  <p className="text-muted font-size-m player-place-custom no-activity">Нет активности</p>
                }
                {Number(playerInfo.visibleInRating) > 0 && <p className="text-muted font-size-m abs-rating">R{amountFormatter(playerInfo.rating)}</p>}
              </div>
              <h4>{playerInfo.surname} {playerInfo.name}</h4>
              <p className="text-secondary-custom mb-1 t-center player-amplua">{playerInfo.amplua || "Нет данных"}</p>


              <ul className="list-group list-group-flush">
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Основание:
                  </h6>
                  <span className="text-secondary-custom">
                    {playerInfo.blade || "нет данных"}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Forehand:
                  </h6>
                  <span className="text-secondary-custom">
                    {playerInfo.forhand || "нет данных"}
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Backhand:
                  </h6>
                  <span className="text-secondary-custom">
                    {playerInfo.backhand || "нет данных"}
                  </span>
                </li>

                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <div className="player-hand-hvat">
                    <div>
                      <h6 className="mb-0">
                        Хват
                      </h6>
                      <span className="text-secondary-custom">
                        {playerInfo.hvat === "euro" ? "Европейский" : "Азиатский/Перо" || "нет данных"}
                      </span>
                    </div>

                    <div>
                      <h6 className="mb-0">
                        Левша / Правша
                      </h6>
                      <span className="text-secondary-custom">
                        {playerInfo.hand === "right" ? "Правая рука" : "Левая рука" || "нет данных"}
                      </span>
                    </div>
                  </div>
                </li>
                {playerInfo.coach && <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Тренер:
                  </h6>
                  <span className="text-secondary-custom">
                    {playerInfo.coach || "нет данных"}
                  </span>
                </li>}
              </ul>
              <h4 className="mb-0 next-info-block">Достижения</h4>
              <ul className="list-group list-group-flush ">
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Максимальный рейтинг:
                  </h6>
                  <span className="text-secondary-custom stat-h4" >
                    <div className="stat-block">
                      <div>Рейтинг</div>
                      <div>{playerInfo.maxRating ? `R${amountFormatter(playerInfo.maxRating[0].bestRating)}` : "нет данных"}</div>
                    </div>
                    <div className="stat-block">
                      <div>Событие</div>
                      <div>{playerInfo.maxRating ? `${playerInfo.maxRating[0].bestEvent}` : "нет данных"}</div>
                    </div>
                    <div className="stat-block">
                      <div>Дата</div>
                      <div>{playerInfo.maxRating ? `${playerInfo.maxRating[0].bestEventDate}` : "нет данных"}</div>
                    </div>
                  </span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap user-racket">
                  <h6 className="mb-0">
                    Максимально высокое место:
                  </h6>

                  <span className="text-secondary-custom stat-h4" >
                    <div className="stat-block">
                      <div>Место</div>
                      <div>{playerInfo.maxPlace ? `#${playerInfo.maxPlace[0].bestPlace}` : "нет данных"}</div>
                    </div>
                    <div className="stat-block">
                      <div>Событие</div>
                      <div>{playerInfo.maxPlace ? `${playerInfo.maxPlace[0].bestEvent}` : "нет данных"}</div>
                    </div>
                    <div className="stat-block">
                      <div>Дата</div>
                      <div>{playerInfo.maxPlace ? `${playerInfo.maxPlace[0].bestEventDate}` : "нет данных"}</div>
                    </div>
                  </span>

                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )

  }

  const renderStatisticForGames = () => {
    return (
      <div className="col-sm-12 mb-3">
        <h6 className="">
          Статистика по % по счетам в играх
        </h6>
        <div className="card h-100">

          <div className="card-body-profile">

            {playerInfo.last3Events && <ul className="list-widget last-3-events">
              <li >
                <h6 className="mb-0">
                  % побед со счётом 3:0
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat30)}%
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  % побед со счётом 3:1
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat31)}%
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  % побед со счётом 3:2
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat32)}%
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  % проигрышей со счётом 0:3
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat03)}%
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  % проигрышей со счётом 1:3
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat13)}%
                </span>
              </li>
              <li >
                <h6 className="mb-0">
                  % проигрышей со счётом 2:3
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {amountFormatter(playerInfo.gamesStatistic.stat23)}%
                </span>
              </li>

              <li >
                <h6 className="mb-0">
                  Всего игр
                </h6>
                <span className="text-secondary-custom last-match-result">
                  {playerInfo.gamesStatistic.total}
                </span>
              </li>


            </ul>}
          </div>
        </div>
      </div>
    )
  }



  const showGamesContainer = () => {

    const content = opponentGames.map((item, index) => {
      const {
        image,
        delta,
        eventDate,
        eventName,
        opponentId,
        opponentName,
        opponentPlace,
        opponentRating,
        scoreOne,
        scoreTwo,
      } = item

      const imagePlaceholder = image && image.length > 0 ? image : NO_IMAGE_URL;

      return (
        <div className="opponent-line" key={index}>
          <div className="opponent-avatar">
            <div
              className="opponent-place"
            >
              #{opponentPlace}
            </div>
            <div
              className="opponent-image"
              style={{
                backgroundImage: `url(${imagePlaceholder})`
              }}
            ></div>
            <div
              className="opponent-rating"
            >
              r{amountFormatter(opponentRating)}
            </div>
            <Link
              to={`/player/${opponentId}`}
              className="opponent-name"
            >
              {opponentName}
            </Link>
          </div>
          <div>
            <div className="opponent-score" > <b>{scoreOne}</b> : <b>{scoreTwo}</b></div>
            <div className="opponent-event" >{eventName}<br /> {eventDate}</div>
            <div className="opponent-delta">
              <span
                className={`text-secondary-custom last-match-result ${Number(delta) < 0 ? "delta-minus" : "delta-plus"}`}
              >
                {Number(delta) >= 0 && "+"}{amountFormatter(delta)}
              </span>
            </div>
          </div>

        </div>
      )
    })

    return content;

  }

  const renderOpponentContainer = () => {
    return (
      (opponentGames.length > 0 && oponent?.name && showGamesList) ? <div className="opponent-container">
        <h4>{oponent?.name} <span onClick={() => setOponent(null)}>(удалить)</span></h4>
        {showGamesContainer()}
      </div> :
        oponent?.name && opponentGames.length === 0 && <div className="opponent-no-games">
          <h4>{oponent?.name} <span onClick={() => setOponent(null)}>(удалить)</span></h4>
          С этим игроком не было ни одной игры
        </div>
    )
  }


  const renderGamesWithOpponent = () => {
    return (
      <div className="games-opponent">
        <h6 className="">
          Посмотреть историю игр с соперником
        </h6>
        <div className="games-filter">
          <Autocomplete
            disablePortal
            id="add-result"
            options={playersList}
            autoHighlight
            value={oponent}
            onChange={(event, newValue) => {
              setOponent(newValue);
            }}
            getOptionLabel={(option) => `${option.name} (${option.rating})`}
            renderInput={(params) => <TextField {...params} label="Выберите игрока" />}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option.name} ({option.rating})
              </Box>
            )}
          />
        </div>

        {fetchingOpponentGames ?
          <div className="spinner-container">
            <CircularProgress size={36} />
          </div>
          : renderOpponentContainer()}

      </div>
    )
  }

  const showHistory = (e, row) => {
    e.stopPropagation();
    setPlayerGamesData(row)
    setShowHistoryModal(true)
  }

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const closePlayerHistory = () => {
    setShowHistoryModal(false)
    setPlayerGamesData(null)
    // setPlayerGames([])
  }
  console.log("attachedUserId", attachedUserId)
  const renderPlayerGames = () => {

    const content = (playerGamesData.eventGames || []).map((game, index) => {
      // console.log("game", game)
      const { playerTwo, delta, scoreOne, scoreTwo, currentRatingPlayerTwo } = game
      return (
        <Grid container spacing={1} className="result-line" key={index}>

          <Grid item xs={6} className="d-flex">
            <b>({amountFormatter(currentRatingPlayerTwo)}) {playerTwo}</b>

          </Grid>
          <Grid item xs={3} className="t-center match-score">
            <b>{scoreOne} : {scoreTwo}</b>
          </Grid>
          <Grid item xs={3}>
            <span className={`${Number(scoreOne) < Number(scoreTwo) ? "delta-minus" : "delta-plus"}`}>{delta > 0 && "+"}{delta}</span>
          </Grid>

        </Grid>
      )
    })

    return content
  }

  const renderTotalDelta = () => {
    let summ = 0
    playerGamesData.eventGames.forEach(g => summ += Number(g.delta))
    return (
      <div className="total-delta">
        Итого: <span className={`${Number(summ) < 0 ? "delta-minus" : summ !== 0 ? "delta-plus" : ""}`}>{summ > 0 && "+"}{amountFormatter(summ)}</span>
      </div>
    )
  }

  const renderHistoryModal = () => {
    let color = ""
    switch (Number(playerGamesData.place)) {
      case 1:
        color = "#FFD700"
        break;
      case 2:
        color = "#A9A9A9";
        break;
      default:
        color = "#b08d57"
        break;
    }

    return (
      <Dialog
        open={showHistoryModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => closePlayerHistory()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="player-games" style={{ minWidth: 600 }}>
          <DialogTitle className="modal-player-name" sx={{ textAlign: "center" }}>
            <EmojiEventsIcon color="primary" sx={{ fill: color }} /> <b>{playerGamesData.place} место</b> / {playerGamesData.eventName}
          </DialogTitle>
          <DialogContent style={{ textAlign: "center" }}>
            {/* <span style={{ marginBottom: 20, fontWeight: 700, display: "block" }}>
              ({`${amountFormatter(playerInfo?.pastRating)} -> ${amountFormatter(playerInfo?.rating)}`})
            </span> */}
            {renderPlayerGames()}
            {renderTotalDelta()}
          </DialogContent>
          <DialogActions>

            <Button onClick={() => closePlayerHistory()} variant="contained">Отмена</Button>

          </DialogActions>
        </div>

      </Dialog >
    )
  }

  const renderLeagueMatches = (leagueId) => {

    const filteredGames = (playerInfo?.leaguesMatches || []).filter(event => Number(event.leagueId) === leagueId);

    const content = filteredGames.map((game, index) => {
      const { eventName, place } = game;

      let color = ""
      switch (Number(place)) {
        case 1:
          color = "#FFD700"
          break;
        case 2:
          color = "#A9A9A9";
          break;
        default:
          color = "#b08d57"
          break;
      }

      return (
        <div key={index} className="games-places-line">
          <div>
            <EmojiEventsIcon color="primary" sx={{ fill: color }} /> <b>{place} место</b> / {eventName}
            <EyeIcon onClick={(e) => showHistory(e, game)} className="show-history" />
          </div>
          {/* showHistory(e, line) */}
          <div></div>
        </div>
      )
    })

    return content;

  }

  const renderEventPlaces = () => {

    const activeLeagueIds = new Set((playerInfo?.leaguesMatches || []).map(event => event.leagueId));
    const filteredTabs = leagueCount.filter(league => activeLeagueIds.has(String(league.id)));

    return (
      <Box sx={{ width: '100%' }} className="places-container">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
            {filteredTabs.map((leadue, index) => {
              return (
                <Tab label={leadue.title} id={leadue.id} key={index} />
              )
            })}
          </Tabs>
        </Box>
        {filteredTabs.map((leadue, index) => {
          return (
            <CustomTabPanel {...{ selectedTab, index }} className="games-container">
              {renderLeagueMatches(leadue.id)}
            </CustomTabPanel>
          )
        })}
      </Box>
    )
  }

  const alertToSeeEventPlaces = () => {
    return (
      <div className="alert-to-see-matches">
        <ErrorIcon />
        <div>
          Просматривать статистику призовых мест могут только игроки, которые привязали свой аккаунт.<br />
          Так же нельзя просматривать статистику игрока, который ещё не привязал аккаунт
        </div>
      </div>
    )
  }


  const renderPlayerStatisticContent = () => {
    return (
      <div className="container">
        <div className="main-body">
          {/* {renderNavigation()} */}

          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              {renderPlayerCard()}

              {renderAllStatistic()}
            </div>
            <div className="col-md-8">
              {/* {Number(playerInfo.visibleInRating) > 0 && <div
                className={classNames({
                  'card': true,
                  'chart-container': true,
                  'd-none': windowWidth <= 500
                })}

              >
                {statisticChart()}
              </div>} */}
              <div className="row gutters-sm right-info-block">
                {renderGamesWithOpponent()}
                {attachedUserId?.playerInfo?.name &&
                  playerInfo.verificated === 1 &&
                  playerInfo?.leaguesMatches?.length > 0 ?
                  renderEventPlaces() :
                  playerInfo?.leaguesMatches?.length > 0 && alertToSeeEventPlaces()}
                {renderStatisticForGames()}
                {renderPlayerLast5Games()}
                {renderStatisticFor3events()}
              </div>
            </div>
          </div>
        </div >
      </div >
    )
  }

  return (

    <AppMainLayout>
      {showHistoryModal && renderHistoryModal()}
      <div className="row">
        <div className="col-md-12">
          {fetchPlayerInfo ?
            <Spinner /> :
            renderPlayerStatisticContent()}
        </div>
      </div>
    </AppMainLayout>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(PlayerDetails)
);