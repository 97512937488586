export const SITE_URL = "http://localhost:3000/";
export const API_URL = 'https://tca.ssv-design.com/Tca';
export const BASE_URL = 'https://tca.ssv-design.com/';
export const ADMIN_EMAIL = "sinser300@gmail.com"

export const NO_IMAGE_URL = BASE_URL + 'uploads/no-image.png';

export const leagueCount = [
    { id: 1, title: "Лига 1" },
    { id: 2, title: "Лига 2" },
    { id: 3, title: "Лига 3" },
    { id: 4, title: "Лига 4" },
    { id: 5, title: "Лига 5" },
    { id: 6, title: "Лига 6" },
]

export const ADD_GAME_RESULT = API_URL + "/addGameResult"
export const GET_EVENT_GAMES = API_URL + "/getEventGames"
export const GET_PLAYERS_LIST = API_URL + "/getPlayersList"
export const EDIT_GAME_RESULT = API_URL + "/editGameResult"
export const DELETE_GAME_RESULT = API_URL + "/deleteGameResult"

export const ADD_EVENT = API_URL + "/addEvent"
export const GET_EVENTS = API_URL + "/getEvents"
export const EDIT_EVENT = API_URL + "/editEvent"
export const DELETE_EVENT = API_URL + "/deleteEvent"


export const ADD_PLAYER = API_URL + "/addPlayer"
export const GET_PLAYERS = API_URL + "/getPlayers"
export const EDIT_PLAYER = API_URL + "/editPlayer"
export const DELETE_PLAYER = API_URL + "/deletePlayer"

export const CALC_EVENT_RATING = API_URL + "/calcEventRating"
export const GET_EVENT_RATING = API_URL + "/getEventRating"
export const GET_PLAYER_INFO = API_URL + "/getPlayerInfo"

export const GET_PLAYER_GAMES = API_URL + "/getPlayerGames"
export const GET_PLAYER_CHART_STATISTIC = API_URL + "/getPlayerChartStatistic"
export const GET_PLAYERS_RATING_FOR_PAGE = API_URL + "/getPlayersRatingForPagePlayerDetails"
export const GET_ATTACHED_PLAYER_ID = API_URL + "/getAttachedPlayerId"
export const REQUEST_ATTACH_PLAYER_ID = API_URL + "/requestAttachPlayerId"
export const GET_NOTIFICATION_LIST = API_URL + "/getNotificationList"
export const REQUEST_ACTION = API_URL + "/requestAction"

export const GET_USER_INFO_BY_EMAIL = API_URL + "/getUserInfoByEmail"
export const EDIT_USER_PROFILE = API_URL + "/editUserProfile"
export const GET_PLAYER_ADS = API_URL + "/getPlayerAds"
export const ADD_PLAYER_ADS = API_URL + "/addPlayerAds"
export const HIDE_ADS = API_URL + "/hideAds"
export const DELETE_ADS = API_URL + "/deleteAds"
export const EDIT_ADS = API_URL + "/editAds"
export const GET_OPPONENT_GAMES = API_URL + "/getOpponentsGames"
export const GET_AFISHA_INFO = API_URL + "/getAfishaInfo"
export const AFISHA_SAVE_DETAILS = API_URL + "/setAfishaDetails"
export const SAVE_PLAYER_PLACE = API_URL + "/savePlayerPlace"  